<template>
  <div class="query-container">
    <header-bar>
      <img
        src="~@/assets/img/切图_web_0628/logo-02.png"
        width="49px"
        height="49px"
      />
      <span class="header-title">艾佳德</span>
    </header-bar>
    <nav-bar />
    <div class="queryContent">
      <div class="publicTitle">
        <div class="logo"></div>
        <div class="title">
          <span class="title-zh">中国心理卫生协会继续教育证书查询</span>
          <div class="title-en">Chinese Association for Mental Health</div>
        </div>
      </div>
      <div class="content">
        <div
          class="box"
          v-show="showType == 'major' && !resultObj.hasOwnProperty('user')"
        >
          <div
            v-for="item in firstLevelList"
            :key="item.index"
            class="certificate"
            @click="showSecondary(item)"
          >
            <span class="title">{{ item.name }}</span>
            <i class="el-icon-right"></i>
          </div>
        </div>
        <div
          class="box"
          v-show="showType == 'secondary' && !resultObj.hasOwnProperty('user')"
        >
          <div
            v-for="item in secondLevelList"
            :key="item.index"
            class="certificate"
            @click="showAuth(item)"
          >
            <span class="title">{{ item.name }}</span>
            <i class="el-icon-right"></i>
          </div>
          <el-button
            type="primary"
            round
            @click="goBack"
            icon="el-icon-back"
            class="backButton"
            size="mini"
          ></el-button>
        </div>
        <div
          v-show="
            showType == 'secondary' &&
            queryItemTitle !== '' &&
            !resultObj.hasOwnProperty('user')
          "
          class="identity-box"
        >
          <div class="tip">
            <h1 style="color: #0185eb; text-align: center">
              {{ queryItemTitle }}
            </h1>
            <div>
              <h6>注意事项：</h6>
              <p class="tips">
                1、末经证书信息权属人同意，不得将此查询材料用违背权属人意愿之用途
              </p>
              <p class="tips">2、此材料仅供查询信息使用，不可用于它处</p>
              <p class="tips">
                3、此入口仅可查询中国心理卫生协会颁发的证书信息
              </p>
            </div>
          </div>
          <div class="form">
            <div class="logo"></div>
            <p style="font-weight: bold">身份证号</p>
            <el-input
              placeholder="请输入身份证号"
              v-model="query.idNumber"
              size="mini"
              clearable
            >
            </el-input>
            <el-button
              type="primary"
              style="width: 100%"
              size="mini"
              @click="confirm()"
              >确定</el-button
            >
          </div>
        </div>
        <div
          v-if="
            resultObj.hasOwnProperty('user') && resultObj.examResult.length > 0
          "
          class="result-Box"
        >
          <div v-if="resultObj.examResult[0].isPass" class="pass">
            <div class="icon"></div>
            <div class="text">恭喜您!</div>
          </div>
          <div v-else class="unpass">
            <div class="icon"></div>
            <div class="text">很遗憾，再接再厉！</div>
          </div>
          <el-divider content-position="left">基本信息</el-divider>
          <el-row :span="24">
            <el-col :span="4" class="label">姓名：</el-col>
            <el-col :span="8">{{ resultObj.user.name }}</el-col>
            <el-col :span="4" class="label">身份证号：</el-col>
            <el-col :span="8">{{ resultObj.user.idNumber }}</el-col>
          </el-row>
          <el-divider content-position="left">考试成绩</el-divider>
          <el-row :span="24">
            <el-col :span="4" class="label">考试分数：</el-col>
            <el-col :span="8">{{ resultObj.examResult[0].score }}</el-col>
          </el-row>
          <el-divider content-position="left">证书信息</el-divider>
          <el-row :span="24">
            <el-col :span="4" class="label">证书名称：</el-col>
            <el-col :span="8">{{ resultObj.certificate.name }}</el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="4" class="label">证书编号：</el-col>
            <el-col :span="8">{{
              resultObj.examResult[0].certificateNumber
            }}</el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="4" class="label">发证日期：</el-col>
            <el-col :span="8">{{ resultObj.examResult[0].at }}</el-col>
          </el-row>
        </div>
        <div
          v-if="
            resultObj.hasOwnProperty('user') && resultObj.examResult.length == 0
          "
          class="result-Box"
        >
          <div class="none">
            <div class="text">暂无考试成绩!</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="请先验证后查询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="padding: 0 100px">
        <el-form
          :model="query"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-row :span="24">
            <el-col>
              <span>{{ `已将验证码发送至您的手机(${phoneNum})` }}</span>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col>
              <el-form-item label="验证码：" prop="code">
                <el-input v-model="query.code" size="mini">
                  <i v-show="showTime" slot="suffix">{{ `${countdown}s` }}</i>
                  <i v-show="!showTime" slot="suffix" @click="confirm()"
                    >获取验证码</i
                  >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col>
              <el-button
                type="primary"
                @click="checkClick('ruleForm')"
                size="mini"
                style="width: 100%"
                >查询</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderBar from "../components/header.vue";
import NavBar from "../components/nav.vue";
import axios from "axios";

export default {
  components: {
    HeaderBar,
    NavBar,
  },
  data() {
    return {
      query: {
        idNumber: "",
        certificateId: "",
        code: "",
      },
      rules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      showType: "major",
      firstLevelList: [],
      secondLevelList: [],
      queryItemTitle: "",
      dialogVisible: false,
      phoneNum: "",
      countdown: 60,
      showTime: false,
      resultObj: {},
    };
  },
  mounted() {
    this.getInfo();
    let docu = document.getElementsByClassName("queryContent")[0];
    docu.style.height = window.innerHeight - 100 - 60 - 274 + "px";
  },
  methods: {
    // 获取所有证书分类
    getInfo() {
      axios
        .get("/v1/evaluation/certificate/query_categorys/", { params: {} })
        .then((response) => {
          this.firstLevelList = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //展示二级分类
    showSecondary(item) {
      this.secondLevelList = item.certificates;
      this.showType = "secondary";
    },
    //展示身份验证
    showAuth(item) {
      this.queryItemTitle = item.name;
      this.query.certificateId = item.id;
    },
    //返回
    goBack() {
      this.showType = "major";
      this.queryItemTitle = "";
      this.query.certificateId = "";
    },
    confirm() {
      // const regex =
      //   /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      // regex.test(this.query.idNumber)
      if (this.query.idNumber !== "") {
        axios
          .post("/v1/account/sms_captcha/", { data: this.query.idNumber })
          .then((response) => {
            this.phoneNum = response.data.phone;
            this.dialogVisible = true;
            let timer = setInterval(() => {
              this.countdown -= 1;
              if (this.countdown <= 0) {
                clearInterval(timer);
                this.showTime = false;
                this.countdown = 60;
              }
            }, 1000);
            this.showTime = true;
          })
          .catch((err) => {
            this.$message.error(err.response.data.detail);
            console.error(err);
          });
      }
    },
    checkClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post("/v1/evaluation/exam_result/", { ...this.query })
            .then((response) => {
              this.resultObj = response.data;
              this.dialogVisible = false;
              this.$refs[formName].resetFields();
            })
            .catch((err) => {
              this.$message.error(err.response.data.detail);
              console.error(err);
            });
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.query-container {
  .header-title {
    font-size: 34px;
    color: #fff;
    font-weight: 500;
    margin-left: 5px;
  }
  .queryContent {
    width: 100%;
    background: url("~@/assets/img/certificate/certificateQueryBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .publicTitle {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      color: #0185eb;
      text-align: center;
      justify-content: center;
      padding: 10px;
      .logo {
        width: 100px;
        height: 100px;
        background: url("~@/assets/img/certificate/logo.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .title {
        // background-color: pink;
      }
      .title-zh {
        padding: 5px;
        border-bottom: 1px solid rgba(1, 133, 235, 0.3);
      }
      .title-en {
        padding: 8px;
      }
    }
    .content {
      height: calc(100% - 120px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        height: 280px;
        width: 800px;
        overflow-y: scroll;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        .certificate {
          width: 500px;
          padding: 10px 26px;
          border: 1px solid #979797;
          border-radius: 5px;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .certificate:hover {
          background-color: #0185eb;
          color: #fff;
        }
        .certificate:nth-child(n + 2) {
          margin-top: 10px;
        }
        .backButton {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      .identity-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 280px;
        width: 800px;
        overflow-y: scroll;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        margin-left: 10px;
        .tip {
          width: 40%;
          .tips {
            font-size: 12px;
            color: #515a6e;
            padding-bottom: 5px;
          }
        }
        .form {
          width: 50%;
          height: 90%;
          border-left: 1px solid #000;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 0 50px;
          .logo {
            width: 150px;
            height: 90px;
            background: url("~@/assets/img/certificate/identityBg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
      .result-Box {
        width: 600px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        .label {
          font-family: SourceHanSansCN-Normal;
          font-size: 14px;
          color: #a6a6a6;
          text-align: right;
        }
        ::v-deep .el-row {
          padding: 10px;
        }
        .pass {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            width: 100px;
            height: 100px;
            background: url("~@/assets/img/result/passed.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .text {
            font-size: 24px;
            margin-left: 20px;
            color: #19be6b;
          }
        }
        .unpass {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            width: 100px;
            height: 100px;
            background: url("~@/assets/img/result/uppassed.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .text {
            font-size: 24px;
            margin-left: 20px;
            color: red;
          }
        }
        .none {
          font-size: 24px;
          text-align: center;
          margin-left: 20px;
          color: rgb(191, 203, 217);
        }
      }
    }
  }
}
</style>
